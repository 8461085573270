<template>
  <div class="Commission">
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
      style="background: #fff;"
    >
      <el-tab-pane label="储值卡提成" name="0">
        <div class="Commission-box">
          <!-- <div class="Commission-box-item">
            <div class="Commission-box-item-name">提成计算：</div>
            <div class="Commission-box-item-cont">
              <el-radio v-model="radio" label="1">售价</el-radio>
              <el-radio v-model="radio" label="2">实收金额</el-radio>
            </div>
          </div> -->

          <!-- <div class="Commission-box-item">
            <div class="Commission-box-item-name">提成方式：</div>
            <div class="Commission-box-item-cont">
              <el-radio v-model="from.type" label="1">固定</el-radio>
              <el-radio v-model="from.type" label="2">百分比</el-radio>
            </div>
          </div> -->
          <div class="Commission-box-item" style="margin:10px 0">
            <el-alert
              title="示例：销售额 1000元，售卡提成点 5%，那么得到的提成为50元。"
              type="warning"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <div class="Commission-box-item">
            <div class="Commission-box-item-name">售卡提成点(百分比)：</div>
            <div class="Commission-box-item-cont">
              <!-- <el-input-number
                v-model="num"
                :precision="2"
                :step="0.1"
                :max="10"
              ></el-input-number>
              <el-button plain disabled style="margin-right:10px;"
                >元</el-button
              > -->
              <el-input-number
                v-model="from.amount"
                :precision="2"
                :step="0.1"
                :min="0"
                :max="100"
              ></el-input-number>
              <div class="bfb">%</div>
              <!-- <el-button plain disabled>%</el-button> -->
            </div>
          </div>
          <div class="Commission-box-item" style="margin:10px 0">
            <el-alert
              title="示例：占比总和为100%，例如提成为50元，甲乙两人提成占比设置为 30%：70%，那么甲得到提成金额为15元，乙得到提成金额为35元；"
              type="warning"
              show-icon
              :closable="false"
            >
            </el-alert>
          </div>
          <div class="Commission-box-item">
            <div class="Commission-box-item-name">多人提成方式：</div>
            <div class="Commission-box-item-cont">
              <el-radio v-model="from.type" label="1">自动平分</el-radio>
              <el-radio v-model="from.type" label="2"
                >按比例设置（最多支持选3位技师或销售）</el-radio
              >
            </div>
          </div>

          <div class="Commission-box-item" v-if="from.type == '2'">
            <div class="Commission-box-item-name"></div>
            <div class="Commission-box-item-cont">
              <div class="Commission-box-item-cont-box">
                <div class="Commission-box-item-cont-list">
                  <div class="Commission-box-item-cont-list-l">二人时</div>
                  <div class="Commission-box-item-cont-list-r">
                    <el-input-number
                      v-model="from.two[0]"
                      :precision="2"
                      :step="0.1"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                    <div class="bfb">%</div>
                    <div class="bfb bfbs" style="margin-left:10px">:</div>
                    <el-input-number
                      style="margin-left:10px;"
                      v-model="from.two[1]"
                      :precision="2"
                      :step="0.1"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                    <div class="bfb">%</div>
                  </div>
                </div>
                <div class="Commission-box-item-cont-list">
                  <div class="Commission-box-item-cont-list-l">三人时</div>
                  <div class="Commission-box-item-cont-list-r">
                    <el-input-number
                      style="width:150px"
                      v-model="from.three[0]"
                      :precision="2"
                      :step="0.1"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                    <div class="bfb">%</div>
                    <div class="bfb bfbs" style="margin-left:10px">:</div>
                    <el-input-number
                      style="width:150px"
                      v-model="from.three[1]"
                      :precision="2"
                      :step="0.1"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                    <div class="bfb" style="ma">%</div>
                    <div class="bfb bfbs" style="margin: 0 10px">:</div>
                    <el-input-number
                      style="width:150px"
                      v-model="from.three[2]"
                      :precision="2"
                      :step="0.1"
                      :min="0"
                      :max="100"
                    ></el-input-number>
                    <div class="bfb">%</div>
                  </div>
                </div>
                <div class="Commission-box-item-cont-list" style="border:none">
                  <div class="Commission-box-item-cont-list-l">多人时</div>
                  <div class="Commission-box-item-cont-list-r">平均分</div>
                </div>
              </div>
            </div>
          </div>
          <div class="Commission-box-item">
            <el-button type="primary" @click="set_value_recharge"
              >保存/修改</el-button
            >
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="次卡提成" name="1">
        <div class="Commission-box">
          <div class="Commission-box-item">
            <div class="Commission-box-item-name">次卡提成点(百分比)：</div>
            <div class="Commission-box-item-cont">
              <el-input-number
                v-model="from1.commi_amount"
                :precision="2"
                :step="0.1"
                :min="0"
                :max="100"
              ></el-input-number>
              <div class="bfb">%</div>
              <!-- <el-button plain disabled>%</el-button> -->
            </div>
          </div>
          <div class="Commission-box-item" style="margin:10px 0">
            <el-alert title="多销售员工时提成平均分成" show-icon type="warning">
            </el-alert>
          </div>

          <div class="Commission-box-item">
            <el-button type="primary" @click="set_project_recharge"
              >保存/修改</el-button
            >
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="项目类提成" name="2">角色管理</el-tab-pane> -->
      <el-tab-pane label="产品类提成" name="3">
        <div class="Commission-box">
          <div class="Commission-box-item">
            <div class="Commission-box-item-name">产品提成点(百分比)：</div>
            <div class="Commission-box-item-cont">
              <el-input-number
                v-model="from3.commi_amount"
                :precision="2"
                :step="0.1"
                :min="0"
                :max="100"
              ></el-input-number>
              <div class="bfb">%</div>
              <!-- <el-button plain disabled>%</el-button> -->
            </div>
          </div>
          <div class="Commission-box-item" style="margin:10px 0">
            <el-alert title="多销售员工时提成平均分成" show-icon type="warning">
            </el-alert>
          </div>

          <div class="Commission-box-item">
            <el-button type="primary" @click="set_product">保存/修改</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="团队提成" name="4">
        <div class="Commission-box">
          <div class="Commission-box-items border-b">
            <div class="Commission-box-items-head">
              <div class="Commission-box-items-head-list border-r">
                适配员工
              </div>
              <div class="Commission-box-items-head-list border-r">
                提成类型
              </div>
              <div class="Commission-box-items-head-list border-r">
                提成方式
              </div>
              <div class="Commission-box-items-head-list">提成点（%）</div>
            </div>
          </div>

          <div
            class="Commission-box-items border-b"
            v-for="(item, index) in from4"
            :key="index"
          >
            <div class="Commission-box-items-list center border-r border-l">
              {{ item.name }}
            </div>
            <div class="Commission-box-items-list border-r">
              <div class="Commission-box-items-list-item border-b">
                储值卡销售提成
              </div>
              <div class="Commission-box-items-list-item border-b">
                次卡销售提成
              </div>
              <div class="Commission-box-items-list-item border-b">
                套餐卡销售提成
              </div>
              <div class="Commission-box-items-list-item border-b">
                项目类销售提成
              </div>
              <div class="Commission-box-items-list-item ">产品销售提成</div>
            </div>
            <div class="Commission-box-items-list border-r center">
              百分比提成
            </div>
            <div class="Commission-box-items-list border-r">
              <div class="Commission-box-items-list-item border-b">
                <el-input-number
                  v-model="item.value_recharge"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="100"
                ></el-input-number>
                <div class="bfb">%</div>
              </div>
              <div class="Commission-box-items-list-item border-b">
                <el-input-number
                  v-model="item.project_recharge"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="100"
                ></el-input-number>
                <div class="bfb">%</div>
              </div>
              <div class="Commission-box-items-list-item border-b">
                <el-input-number
                  v-model="item.card"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="100"
                ></el-input-number>
                <div class="bfb">%</div>
              </div>
              <div class="Commission-box-items-list-item border-b">
                <el-input-number
                  v-model="item.project"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="100"
                ></el-input-number>
                <div class="bfb">%</div>
              </div>
              <div class="Commission-box-items-list-item">
                <el-input-number
                  v-model="item.product"
                  :precision="2"
                  :step="0.1"
                  :min="0"
                  :max="100"
                ></el-input-number>
                <div class="bfb">%</div>
              </div>
            </div>
          </div>

          <div class="Commission-box-item" style="margin-top:15px;">
            <el-button type="primary" @click="set_manager">保存/修改</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="团队营业收入提成" name="5" v-if="0"
        >努力开发中，敬请期待...</el-tab-pane
      >
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "0",
      from: {
        type: "2",
        amount: 1,
        two: [1, 50],
        three: [60, 20, 20],
      },
      from1: { commi_amount: 10 },
      from3: {
        commi_amount: 10,
      },
      from4_data: [
        { role_id: 3, name: "店长" },
        { role_id: 4, name: "经理" },
        { role_id: 5, name: "美导" },
        { role_id: 7, name: "收银" },
      ],
      from4: [],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    /* 获取员工 */
    getStaff() {
      this.$axios({
        href: "/api/app/meiye/user/store",
        data: { status: 1 },
      }).then((res) => {
        console.log(res);
      });
    },
    /* 获取储值卡提成*/
    get_value_recharge() {
      this.$axios({
        href: "/api/app/meiye/commiconfig/get_value_recharge",
      }).then((res) => {
        console.log(res);

        if (res.data.type == 1) {
          res.data.two = [50, 50];
          res.data.three = [60, 20, 20];
        }
        res.data.type += "";
        this.from = res.data;
      });
    },
    /* 修改储值卡提成 */
    set_value_recharge() {
      let d = JSON.parse(JSON.stringify(this.from));

      if (d.type == "2") {
        let t = d.two[0] + d.two[1];
        let th = d.three[0] + d.three[1] + d.three[2];
        if (t > 100) {
          this.$message.error("二人提成之和不能超过100%");
          return false;
        }
        if (th > 100) {
          this.$message.error("三人提成之和不能超过100%");
          return false;
        }
      }

      d.two = JSON.stringify(d.two);
      d.three = JSON.stringify(d.three);
      this.$confirm("此操作将修改储值卡提成计划, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/commiconfig/value_recharge",
            data: d,
          }).then(() => {
            this.get_value_recharge();
            this.$message({
              message: "修改成功",
              type: "success",
            });
          });
        })
        .catch(() => {});
    },
    /* 获取商品提成 */
    get_product() {
      this.$axios({
        href: "/api/app/meiye/commiconfig/get_product",
      }).then((res) => {
        this.from3 = res.data;
      });
    },
    /* 修改商品提成 */
    set_product() {
      this.$confirm("此操作将修改产品提成计划, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            href: "/api/app/meiye/commiconfig/product",
            data: this.from3,
          }).then(() => {
            this.get_product();
            this.$message({
              message: "修改成功",
              type: "success",
            });
          });
        })
        .catch(() => {});
    },
    /* 获取团队提成 */
    get_manager() {
      let len = this.from4_data.length;
      let _this = this;
      _this.from4 = [];
      for (let i = 0; i < len; i++) {
        _this
          .$axios({
            href: "/api/app/meiye/commiconfig/get_manager",
            data: { role_id: _this.from4_data[i].role_id },
          })
          .then((res) => {
            console.log(res);
            _this.from4.push({
              role_id: _this.from4_data[i].role_id,
              name: _this.from4_data[i].name,
              value_recharge: res.data.value_recharge || 1,
              project_recharge: res.data.project_recharge || 1,
              product: res.data.product || 1,
              project: res.data.project || 1,
              card: res.data.card || 1,
            });
          });
      }
    },
    /* 修改团队提成 */
    set_manager() {
      this.$confirm("此操作将修改团队提成计划, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let len = this.from4_data.length;

          for (let i = 0; i < len; i++) {
            this.$axios({
              href: "/api/app/meiye/commiconfig/manager",
              data: this.from4[i],
            }).then(() => {
              if (i == len - 1) {
                this.get_manager();
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
              }
            });
          }
        })
        .catch(() => {});
    },
    /* 次卡提成 */
    get_project_recharge() {
      this.$axios({
        href: "/api/app/meiye/commiconfig/get_project_recharge",
      }).then((res) => {
        this.from1 = res.data;
      });
    },
    /* 修改次卡提成点 */
    set_project_recharge() {
      this.$axios({
        href: "/api/app/meiye/commiconfig/project_recharge",
        data: this.from1,
      }).then(() => {
        this.get_project_recharge();
        this.$message({
          message: "修改成功",
          type: "success",
        });
      });
    },
  },
  created() {
    this.get_value_recharge();
    this.get_product();
    this.get_manager();
    this.get_project_recharge();
  },
};
</script>

<style lang="scss" scoped>
.Commission {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  &-box {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 13px;
    box-sizing: border-box;
    &-item {
      width: 100%;
      min-height: 45px;
      //   background: red;
      display: flex;
      &-name {
        width: 200px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 10px;
      }
      &-cont {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &-box {
          min-width: 60%;
          height: 100%;
          border: 1px solid #ebeef5;
        }
        &-list {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 13px;
          border-bottom: 1px solid #ebeef5;
          &-l {
            width: 120px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #ebeef5;
          }
          &-ls {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #ebeef5;
          }
          &-r {
            height: 50px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            // border-bottom: 1px solid #ebeef5;
          }
        }
      }
    }
    &-items {
      width: 100%;
      height: auto;
      display: flex;
      &-head {
        width: 100%;
        height: 60px;
        background: #f5f7fa;
        border: 1px solid #ebeef5;
        border-bottom: none;
        display: flex;
        &-list {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &-list {
        width: 100%;
        height: auto;
        &-item {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.border-none {
  border: none;
}
.border {
  border: 1px solid #ebeef5;
}
.border-l {
  border-left: 1px solid #ebeef5;
}
.border-t {
  border-top: 1px solid #ebeef5;
}
.border-r {
  border-right: 1px solid #ebeef5;
}
.border-r-none {
  border-right: none;
}
.border-b {
  border-bottom: 1px solid #ebeef5;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bfb {
  min-width: 40px;
  height: 38px;
  // font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  // border-left: none;
  margin-left: 1px;
  z-index: 9;
  transition: all 0.5s;
}
.bfbs {
  background: rgba(1, 1, 1, 0);
  border: none;
  font-weight: 800;
}
.bfb:hover{
  border: 1px solid #409eff;
}
.bfbs:hover{
  border:none;
}
// .Commission-box-item-cont .el-input-number:hover + .bfb ,.Commission-box-items-list-item .el-input-number:hover + .bfb{
//   border-left: 1px solid #409eff;
// }
// .Commission-box-item-cont .el-input__inner:hover < .el-input-number + .bfb{
//   border-left: 1px solid #c0c4cc;
// }
</style>
